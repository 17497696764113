
import { Component, Vue, Watch } from "vue-property-decorator";
import { GaodeService, UserService, PackageService, OrderService, FamilyService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { EnumUtils, tools, Pay } from "src/utils";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class OfteUser extends Vue {
  /**
   * 时间处理
   * @private
   * @returns
   */
  private dayjs: any = dayjs;

  /**
   * 自选项目选中
   * @private
   * @returns any
   */
  private fixedCollapseActive: any = [];

  /**
   * 自选项目选中
   * @private
   * @returns any
   */
  private collapseActiveNames: any = [];

  /**
   * 订单详情
   * @private
   * @returns
   */
  private orderDetail: any = {};

  /**
   * 订单id
   * @private
   * @returns string
   */
  private get orderNo(): string {
    if (this.$route.query && this.$route.query.orderNo) {
      return (this.$route.query.orderNo as string) || "";
    }

    return null;
  }

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("$route", { deep: true, immediate: true })
  protected async updateOpen(to: any): Promise<void> {
    if (this.orderNo) {
      this.getOrderDetail();
    }

    if (!(await tools.isWechat())) {
      return;
    }
    let openid = localStorage.getItem("openid");
    if (!this.$route.query.openid && !openid) {
      this.wechatOauth();
    } else if (this.$route.query.openid) {
      localStorage.setItem("openid", this.$route.query.openid as string);
    }
  }

  /**
   * 获取订单详情
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async getOrderDetail(): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.getAllowAnonymou(this.orderNo);

      if (result) {
        this.orderDetail = result.data;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 页面是否回退-h5环境
   * @private
   * @returns boolean
   */
  private get goback(): boolean {
    if (this.$route.query && this.$route.query.goback) {
      return true;
    }

    return false;
  }

  /**
   * 生命钩子函数
   * @private
   * @returns void
   */
  private async created(): Promise<void> {
    if (!(await tools.isWechat())) {
      // h5支付跳转
      if (this.goback) {
        if (localStorage.getItem("h5Pay")) {
          Dialog.confirm({
            title: "支付结果",
            message: "请确定支付是否已完成?",
            confirmButtonText: "已完成支付",
            cancelButtonText: "重新支付",
          })
            .then(() => {
              localStorage.removeItem("h5Pay");
              this.getOrderDetail();
            })
            .catch(() => {
              localStorage.removeItem("h5Pay");
              this.getOrderDetail();
            });
        }
      }
    }
  }

  /**
   * 支付
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async proceedPay(): Promise<void> {
    this.$router.push({ name: "pay", query: { orderNo: this.orderNo, anonymous: 1 + "" } });
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  /**
   * 微信授权
   * @private
   * @returns void
   */
  private async wechatOauth(): Promise<void> {
    let url = location.href;

    try {
      let { content: result } = await UserService.instance.wechatOauth(url, true);
      if (result.data) {
        location.href = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 显示提示
   * @private
   * @returns void
   */
  private showTip(remark: string): void {
    Dialog.alert({
      messageAlign: "left",
      confirmButtonColor: "#00d5c1",
      message: remark,
      confirmButtonText: "确定",
    });
  }
}
